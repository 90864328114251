export default {
  watch: {
    errors() {
      const nodeName = this.$el?.querySelector('input')?.name

      if (!nodeName) return

      this.$nextTick(() => {
        if (nodeName && !this.errors?.[nodeName]?.[0]) {
          this.$el
            ?.querySelector('input')
            ?.setAttribute('aria-invalid', 'false')
          return
        }

        this.$el
          ?.querySelector('.ucsc-field__message.ucsc-field__message--danger')
          ?.setAttribute('role', 'alert')
        nodeName &&
          this.$el?.querySelector('input')?.setAttribute('aria-invalid', 'true')
      })
    },
  },
}
